import React, { Component } from 'react';

import Channels from "./sidebarSubComponents/Channels";
import Users from "./sidebarSubComponents/Users";
import Settings from './sidebarSubComponents/Settings';

class Sidebar extends Component {

    constructor(props) {

        super(props);

        this.state = {
            filter: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {

        e.preventDefault();

        this.setState({ filter: e.target.value });
    }

    render() {
        return (
            <div className="sidebar sidebar-expand-lg order-1 order-lg-0">
                <div className="container py-5 px-lg-5">
                    <form>
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-search"><g data-name="Layer 2"><g data-name="search"><rect width={24} height={24} opacity={0} /><path d="M20.71 19.29l-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z" /></g></g></svg>
                            </div>
                            <input className="form-control form-control-lg" type="search" placeholder="Search" aria-label="Search" onChange={(e) => this.handleChange(e)} />
                        </div>
                    </form>
                    <div className="tab-content">
                        <Channels filter={this.state.filter} />
                        <Users filter={this.state.filter} />
                        <Settings />
                    </div>
                </div>
            </div>
        );
      }
}

export default Sidebar;
