import React, { Component } from 'react';
import {fetchUsersSuccess} from "../../actions/userActions";
import {getUsers, getUsersError, getUsersPending} from "../../reducers/users";
import {bindActionCreators} from "redux";
import fetchUsersAction from "../../helpers/FetchUsers";
import {connect} from "react-redux";
import {userId} from "../../socket";
import store from "../../store";
import { API_URL } from "../../config";
import socket from "../../socket";
import COLORS from "../../constants/Colors";

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            location: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.socket = socket;
    }

    handleChange(e, state) {

        e.preventDefault();

        this.setState({
            [state]: e.target.value
        });
    }

    changeBubbleColor(color) {

        let { users } = this.props.users;

        for(let user of users) {
            if(user.userId === userId)
                user.preferredColor = color;
        }

        store.dispatch(fetchUsersSuccess(users));

        fetch(`${ API_URL }/api/users/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({preferredColor: color})
        })
            .then(() => this.socket.emit('updateUserSettings'))
            .catch(e => console.log(e));

    }

    handleSubmit() {

        let { users } = this.props.users;
        const { fullName, location } = this.state;

        let data = {};

        if(fullName !== '')
            data['fullName'] = fullName;
        if(location !== '')
            data['location'] = location;

        for(let i = 0; i < users.length; i++)
            if(users[i].userId === userId)
                users[i] = {...users[i], ...data};

        store.dispatch(fetchUsersSuccess(users));

        fetch(`${ API_URL }/api/users/${userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(() => this.socket.emit('updateUserSettings'))
            .catch(e => console.log(e));

    }

    render() {

        return (
            <div className="tab-pane fade" id="settings" role="tabpanel">
                <h3 className="my-5">Settings</h3>
                <hr className="mb-0" />
                <div id="accordionOne">
                    <div className="accordion-item">
                        <div className="accordion-header" id="headingOne" data-toggle="collapse" data-target="#collapseOne" role="button" aria-expanded="false" aria-controls="collapseOne">
                            <div className="mr-auto">
                                <h6 className="mb-1 lh-150">My Account</h6>
                                <p>Change your data.</p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-arrow-ios-forward"><g data-name="Layer 2"><g data-name="arrow-ios-forward"><rect width={24} height={24} transform="rotate(-90 12 12)" opacity={0} /><path d="M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z" /></g></g></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-arrow-ios-downward"><g data-name="Layer 2"><g data-name="arrow-ios-downward"><rect width={24} height={24} opacity={0} /><path d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z" /></g></g></svg>
                        </div>
                        <div className="collapse" id="collapseOne" data-parent="#accordionOne" aria-labelledby="headingOne">
                            <div className="accordion-body">
                                <label htmlFor="fullName">Full name</label>
                                <input className="form-control form-control-lg mb-5" id="fullName" type="text" placeholder="Full name" value={this.state.fullName} onChange={e => this.handleChange(e, 'fullName')}/>
                                <label htmlFor="location">City</label>
                                <input className="form-control form-control-lg mb-5" id="location" type="text" placeholder="City" value={this.state.location} onChange={e => this.handleChange(e, 'location')}/>
                                <button className="btn btn-block btn-lg btn-primary" type="submit" onClick={e => this.handleSubmit()}>Apply changes</button>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item">
                        <div className="accordion-header" id="headingFive" data-toggle="collapse" data-target="#collapseFive" role="button" aria-expanded="false" aria-controls="collapseFive">
                            <div className="mr-auto">
                                <h6 className="mb-1 lh-150">Appearance</h6>
                                <p>Choose your color.</p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-arrow-ios-forward"><g data-name="Layer 2"><g data-name="arrow-ios-forward"><rect width={24} height={24} transform="rotate(-90 12 12)" opacity={0} /><path d="M10 19a1 1 0 0 1-.64-.23 1 1 0 0 1-.13-1.41L13.71 12 9.39 6.63a1 1 0 0 1 .15-1.41 1 1 0 0 1 1.46.15l4.83 6a1 1 0 0 1 0 1.27l-5 6A1 1 0 0 1 10 19z" /></g></g></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-arrow-ios-downward"><g data-name="Layer 2"><g data-name="arrow-ios-downward"><rect width={24} height={24} opacity={0} /><path d="M12 16a1 1 0 0 1-.64-.23l-6-5a1 1 0 1 1 1.28-1.54L12 13.71l5.36-4.32a1 1 0 0 1 1.41.15 1 1 0 0 1-.14 1.46l-6 4.83A1 1 0 0 1 12 16z" /></g></g></svg>
                        </div>
                        <div className="collapse" id="collapseFive" data-parent="#accordionOne" aria-labelledby="headingFive">
                            <div className="accordion-body">
                                {
                                    COLORS.map(color => (
                                        <button className='btn btn-sm btn-circle m-2' key={color} style={{background: color}} onClick={() => this.changeBubbleColor(color)}>
                                            &nbsp;
                                        </button>
                                    ))
                                }
                            </div>
                        </div>
                        <hr className="my-0" />
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Settings);