import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import PropTypes from 'prop-types';

import socket, {userId} from "../../socket";
import store from "../../store";
import activeChatroom from "../../actions/activeChatroomActions";
import {faUserAltSlash} from "@fortawesome/free-solid-svg-icons/faUserAltSlash";
import ListParticipants from "../headerSubComponents/ListParticipants";
import {AUDIO, VIDEO} from "../../constants/CallModalConstants";
import {getOnlineUsers} from "../../reducers/onlineUsers";
import {getActiveChatroom} from "../../reducers/activeChatroom";
import {bindActionCreators} from "redux";
import dispatchOnlineUsers from "../../helpers/GetOnlineUsers";
import {connect} from "react-redux";
import setActiveChatroom from "../../helpers/GetActiveChatroom";
import getPreferredColor from "../../helpers/GetPreferredColor";
import EditChannel from "../headerSubComponents/EditChannel";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            whichList: 'add'
        };

        this.handleDeleteChat = this.handleDeleteChat.bind(this);
        this.handleLeaveChat = this.handleLeaveChat.bind(this);
        this.handleListUsers = this.handleListUsers.bind(this);

        this.socket = socket;
    }

    handleDeleteChat() {

        this.socket.emit('removeRoom');

        store.dispatch(activeChatroom(null));
    }

    handleLeaveChat() {

        this.socket.emit('removeUsersFromRoom', [userId]);

        store.dispatch(activeChatroom(null));
    }

    handleListUsers(e, whichList) {

        e.preventDefault();

        this.setState({
            whichList
        });
    }

    handleCallClick(value) {

        this.props.onHandleModal(value);
        this.socket.emit('mediaCall', value);
    }

    render() {

        const { activeChatroom } = this.props.activeChatroom;

        return (
            <div>
                <div className="chat-header">
                    <div className="d-flex mr-auto">
                        <span className="avatar avatar-sm status mr-3 rounded-circle" style={{background: getPreferredColor(activeChatroom)}}>{activeChatroom.name[0].toUpperCase()}</span>
                        <div className="channelName">
                            <h6 className="mb-2 lh-100">{activeChatroom.name}</h6>
                            <p className="lh-100">{activeChatroom.participantIds.length} participants</p>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <button className="btn d-none d-xl-inline-block" type="button" data-toggle="modal" data-target="#media" onClick={() => this.handleCallClick(VIDEO)}>
                            <i className="eva-hover">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     className="eva eva-video eva-md eva-animation eva-icon-hover-pulse">
                                    <g data-name="Layer 2">
                                        <g data-name="video">
                                            <rect width="24" height="24" opacity="0">

                                            </rect>
                                            <path
                                                d="M21 7.15a1.7 1.7 0 0 0-1.85.3l-2.15 2V8a3 3 0 0 0-3-3H5a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h9a3 3 0 0 0 3-3v-1.45l2.16 2a1.74 1.74 0 0 0 1.16.45 1.68 1.68 0 0 0 .69-.15 1.6 1.6 0 0 0 1-1.48V8.63A1.6 1.6 0 0 0 21 7.15z">

                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </i>
                        </button>
                        <button className="btn d-none d-xl-inline-block ml-5" type="button" data-toggle="modal" data-target="#media" onClick={() => this.handleCallClick(AUDIO)}>
                            <i className="eva-hover">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     className="eva eva-phone-call eva-md eva-animation eva-icon-hover-pulse">
                                    <g data-name="Layer 2">
                                        <g data-name="phone-call">
                                            <rect width="24" height="24" opacity="0">

                                            </rect>
                                            <path d="M13 8a3 3 0 0 1 3 3 1 1 0 0 0 2 0 5 5 0 0 0-5-5 1 1 0 0 0 0 2z">

                                            </path>
                                            <path d="M13 4a7 7 0 0 1 7 7 1 1 0 0 0 2 0 9 9 0 0 0-9-9 1 1 0 0 0 0 2z">

                                            </path>
                                            <path
                                                d="M21.75 15.91a1 1 0 0 0-.72-.65l-6-1.37a1 1 0 0 0-.92.26c-.14.13-.15.14-.8 1.38a9.91 9.91 0 0 1-4.87-4.89C9.71 10 9.72 10 9.85 9.85a1 1 0 0 0 .26-.92L8.74 3a1 1 0 0 0-.65-.72 3.79 3.79 0 0 0-.72-.18A3.94 3.94 0 0 0 6.6 2 4.6 4.6 0 0 0 2 6.6 15.42 15.42 0 0 0 17.4 22a4.6 4.6 0 0 0 4.6-4.6 4.77 4.77 0 0 0-.06-.76 4.34 4.34 0 0 0-.19-.73z">

                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </i>
                        </button>
                        <button className="btn d-lg-none ml-5" data-toggle="chat-offcanvas" type="button">
                            <i className="eva-hover">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                     className="eva eva-arrow-circle-left eva-md eva-animation eva-icon-hover-pulse">
                                    <g data-name="Layer 2">
                                        <g data-name="arrow-circle-left">
                                            <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0">

                                            </rect>
                                            <path
                                                d="M22 12a10 10 0 1 0-10 10 10 10 0 0 0 10-10zm-11.86 3.69l-2.86-3a.49.49 0 0 1-.1-.15.54.54 0 0 1-.1-.16.94.94 0 0 1 0-.76 1 1 0 0 1 .21-.33l3-3a1 1 0 0 1 1.42 1.42L10.41 11H16a1 1 0 0 1 0 2h-5.66l1.25 1.31a1 1 0 0 1-1.45 1.38z">

                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </i>
                        </button>
                        <div className="dropdown align-self-center ml-5">
                            <button className="btn d-none d-xl-inline-block" data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded="false">
                                <i className="eva-hover">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                         className="eva eva-more-horizontal-outline eva-md eva-animation eva-icon-hover-pulse">
                                        <g data-name="Layer 2">
                                            <g data-name="more-horizontal-outline">
                                                <rect width="24" height="24" opacity="0"/>
                                                <circle cx="12" cy="12" r="2"/>
                                                <circle cx="19" cy="12" r="2"/>
                                                <circle cx="5" cy="12" r="2"/>
                                            </g>
                                        </g>
                                    </svg>
                                </i>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <button className="dropdown-item" type="button" data-toggle="modal" data-target="#editChatData">
                                    <div className="btn btn-sm btn-circle btn-neutral mr-3">
                                        <FontAwesomeIcon icon={faEdit} />
                                    </div>
                                    Edit channel
                                </button>
                                <button className="dropdown-item" type="button" data-toggle="modal" data-target="#listParticipants" onClick={e => this.handleListUsers(e, 'add')}>
                                    <div className="btn btn-sm btn-circle btn-neutral mr-3">
                                        <FontAwesomeIcon icon={faUserPlus} />
                                    </div>
                                    Add participants to chat
                                </button>
                                <button className="dropdown-item" type="button" data-toggle="modal" data-target="#listParticipants" onClick={e => this.handleListUsers(e, 'remove')}>
                                    <div className="btn btn-sm btn-circle btn-neutral mr-3">
                                        <FontAwesomeIcon icon={faUserAltSlash} />
                                    </div>
                                    Remove participants from chat
                                </button>
                                <button className="dropdown-item" type="button" onClick={this.handleLeaveChat}>
                                    <div className="btn btn-sm btn-circle btn-neutral mr-3">
                                        <FontAwesomeIcon icon={faSignOutAlt} />
                                    </div>
                                    Leave chat
                                </button>
                                <button className="dropdown-item" type="button" onClick={this.handleDeleteChat}>
                                    <div className="btn btn-sm btn-circle btn-neutral mr-3">
                                        <FontAwesomeIcon icon={faTrashAlt} />
                                    </div>
                                    Delete chat
                                </button>
                            </div>
                        </div>
                        <div className="dropdown d-xl-none ml-5">
                            <button className="btn" data-toggle="dropdown" type="button" aria-haspopup="true"
                                    aria-expanded="false">
                                <i className="eva-hover">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                         className="eva eva-settings-2 eva-md eva-animation eva-icon-hover-pulse">
                                        <g data-name="Layer 2">
                                            <g data-name="settings-2">
                                                <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0">

                                                </rect>
                                                <circle cx="12" cy="12" r="1.5">

                                                </circle>
                                                <path
                                                    d="M20.32 9.37h-1.09c-.14 0-.24-.11-.3-.26a.34.34 0 0 1 0-.37l.81-.74a1.63 1.63 0 0 0 .5-1.18 1.67 1.67 0 0 0-.5-1.19L18.4 4.26a1.67 1.67 0 0 0-2.37 0l-.77.74a.38.38 0 0 1-.41 0 .34.34 0 0 1-.22-.29V3.68A1.68 1.68 0 0 0 13 2h-1.94a1.69 1.69 0 0 0-1.69 1.68v1.09c0 .14-.11.24-.26.3a.34.34 0 0 1-.37 0L8 4.26a1.72 1.72 0 0 0-1.19-.5 1.65 1.65 0 0 0-1.18.5L4.26 5.6a1.67 1.67 0 0 0 0 2.4l.74.74a.38.38 0 0 1 0 .41.34.34 0 0 1-.29.22H3.68A1.68 1.68 0 0 0 2 11.05v1.89a1.69 1.69 0 0 0 1.68 1.69h1.09c.14 0 .24.11.3.26a.34.34 0 0 1 0 .37l-.81.74a1.72 1.72 0 0 0-.5 1.19 1.66 1.66 0 0 0 .5 1.19l1.34 1.36a1.67 1.67 0 0 0 2.37 0l.77-.74a.38.38 0 0 1 .41 0 .34.34 0 0 1 .22.29v1.09A1.68 1.68 0 0 0 11.05 22h1.89a1.69 1.69 0 0 0 1.69-1.68v-1.09c0-.14.11-.24.26-.3a.34.34 0 0 1 .37 0l.76.77a1.72 1.72 0 0 0 1.19.5 1.65 1.65 0 0 0 1.18-.5l1.34-1.34a1.67 1.67 0 0 0 0-2.37l-.73-.73a.34.34 0 0 1 0-.37.34.34 0 0 1 .29-.22h1.09A1.68 1.68 0 0 0 22 13v-1.94a1.69 1.69 0 0 0-1.68-1.69zM12 15.5a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5z">

                                                </path>
                                            </g>
                                        </g>
                                    </svg>
                                </i>
                                <span className="sr-only">Toggle Dropdown</span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-right">
                                <button className="dropdown-item" type="button">Video call</button>
                                <button className="dropdown-item" type="button">Voice call</button>
                                <button className="dropdown-item" type="button">Members</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ListParticipants forUse={this.state.whichList} />
                <EditChannel />
            </div>
        );
    }
}

Header.propTypes = {
    active: PropTypes.object,
    onHandleModals: PropTypes.func
};

const mapStateToProps = (state) => ({
    onlineUsers: getOnlineUsers(state),
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    getOnlineUsers: dispatchOnlineUsers,
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
