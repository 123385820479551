import React, { Component } from 'react';
import RTCMultiConnection from "rtcmulticonnection";

import socket, {userId} from "../../socket";
import {AUDIO, HIDE, VIDEO} from "../../constants/CallModalConstants";
import {getUsers, getUsersError, getUsersPending} from "../../reducers/users";
import {getActiveChatroom} from "../../reducers/activeChatroom";
import {bindActionCreators} from "redux";
import fetchUsersAction from "../../helpers/FetchUsers";
import setActiveChatroom from "../../helpers/GetActiveChatroom";
import {connect} from "react-redux";
import {API_URL} from "../../config";
import getPreferredColor from "../../helpers/GetPreferredColor";
import getInitials from "../../helpers/Initials";

class Media extends Component {

    constructor(props) {

        super(props);

        this.state = {};

        this.connection = null;

        this.disconnect = this.disconnect.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        this.connection = new RTCMultiConnection();

        if(this.props.show) {

            this.connection.socketURL = `${ API_URL }/`;
            this.connection.userid = userId;

            this.connection.session = {
                audio: true
            };

            this.connection.sdpConstraints.mandatory = {
                OfferToReceiveAudio: true
            };

            this.connection.mediaConstraints = {
                audio: true
            };

            if(this.props.show === VIDEO && this.connection.DetectRTC.hasWebcam) {

                this.connection.session.video = true;
                this.connection.sdpConstraints.mandatory.OfferToReceiveVideo = true;
                this.connection.mediaConstraints.video = true;
            }

            this.connection.onstream = (event) => {

                event.mediaElement.className = `${event.type.toLowerCase()}Stream`;
                event.mediaElement.controls = true;

                let userData = {};
                for (const user of this.props.users.users)
                    if (user.userId === event.userid)
                        userData = user;

                const container = document.createElement('div');
                container.className = 'containerForMedia';
                container.id = `container_${event.mediaElement.id}`;

                const userInfoElement = document.createElement('div');
                userInfoElement.className = 'mediaText';
                userInfoElement.innerHTML = userData.fullName;
                userInfoElement.style.zIndex = '1';

                this.connection.DetectRTC.load(() => {

                    let ok = true;

                    if(this.connection.DetectRTC.hasMicrophone === false) {

                        ok = false;
                        if(userData.userId === userId)
                            alert('Please attach a microphone device.');
                    }

                    if(this.connection.DetectRTC.hasSpeakers === false) {

                        ok = false;
                        if(userData.userId === userId)
                            alert('Please attach a speaker device. You will unable to hear the incoming audios.');
                    }

                    if (this.props.show === VIDEO && this.connection.DetectRTC.hasWebcam === false) {

                        ok = false;
                        if(userData.userId === userId)
                            alert('Please attach a camera device.');
                    }

                    if(ok) {
                        container.appendChild(event.mediaElement);
                        container.appendChild(userInfoElement);
                    }
                    else {

                        const avatar = document.createElement('div');
                        avatar.className = 'avatar avatar-max rounded-circle';
                        avatar.innerHTML = getInitials(userData.fullName);
                        avatar.style.backgroundColor = getPreferredColor(userData);
                        avatar.style.zIndex = '1';

                        container.appendChild(avatar);
                    }

                    document.getElementById('mediaContainer').prepend(container);
                });
            };

            this.connection.onstreamended = (event) => {

                const tag = document.getElementById(`container_${event.mediaElement.id}`);
                const container = document.getElementById('mediaContainer');

                if(tag)
                    container.removeChild(tag);

                this.connection.disconnectWith(event.useid);
            };

            const { _id } = this.props.activeChatroom.activeChatroom;

            this.connection.openOrJoin(`${_id}_${this.props.show}`);
        }
    }

    disconnect() {

        document.getElementById('mediaContainer').innerHTML = '';

        this.connection.getAllParticipants().forEach(id => {
            this.connection.disconnectWith(id);
        });

        this.connection.attachStreams.forEach(stream => {
            stream.stop();
        });

        if(this.connection.getAllParticipants().length <= 2)
            this.connection.closeSocket();

        this.props.onHandleModal(HIDE);
    }

    render() {

        return (
            <div className="modal fade show modal-fullscreen modal-stream" id="media" tabIndex={-1} role="dialog" aria-labelledby="mediaLabel" aria-modal="true" data-keyboard="false" data-backdrop="static">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content d-flex justify-content-between">

                        <div className="modal-body">
                            <div id="mediaContainer">
                            </div>

                            <div className="controls">
                                <button className="btn btn-circle btn-danger rounded" type="button" data-dismiss="modal" aria-label="Close" onClick={this.disconnect}>
                                    <i className="gi-animation-hover">
                                        <svg className="gi gi-phone-fill gi-animation gi-animation-hover-shake fs-md" width="1em" height="1em" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.4 22A15.42 15.42 0 0 1 2 6.6 4.6 4.6 0 0 1 6.6 2a3.94 3.94 0 0 1 .77.07 3.79 3.79 0 0 1 .72.18 1 1 0 0 1 .65.75l1.37 6a1 1 0 0 1-.26.92c-.13.14-.14.15-1.37.79a9.91 9.91 0 0 0 4.87 4.89c.65-1.24.66-1.25.8-1.38a1 1 0 0 1 .92-.26l6 1.37a1 1 0 0 1 .72.65 4.34 4.34 0 0 1 .19.73 4.77 4.77 0 0 1 .06.76A4.6 4.6 0 0 1 17.4 22z" />
                                        </svg>
                                    </i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
};

const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state),
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction,
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Media);
