export default function getFileSize(sizes) {

    if(!sizes)
        return '';

    let obj = {};
    obj['B'] = sizes.B;
    obj['KB'] = sizes.KB;
    obj['MB'] = sizes.MB;
    obj['GB'] = sizes.GB;
    obj['TB'] = sizes.TB;
    obj['PB'] = sizes.PB;

    let idx = undefined;

    for(const key in obj)
        if(obj[key] > 1)
            idx = key;

    if(idx)
        return `${obj[idx].toFixed(2)} ${idx}`;
    return '';
}