import React, { Component } from 'react';

import getInitials from '../../helpers/Initials';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {getUsers, getUsersError, getUsersPending} from "../../reducers/users";
import fetchUsersAction from "../../helpers/FetchUsers";
import {getOnlineUsers} from "../../reducers/onlineUsers";
import dispatchOnlineUsers from "../../helpers/GetOnlineUsers";
import getPreferredColor from "../../helpers/GetPreferredColor";

class Users extends Component {

    constructor(props) {

        super(props);

        this.state = {};
    }

    render() {

        const { onlineUsers } = this.props.onlineUsers;
        let { users } = this.props.users;
        const { filter } = this.props;

        if(users.length > 0) {

            if(filter)
                users = users.filter(({fullName}) => { return fullName.toLowerCase().includes(filter) });

            const onlineUsersData = users.filter((user) => {
                return onlineUsers.has(user.userId);
            });
            const offlineUsersData = users.filter((user) => {
                return !onlineUsers.has(user.userId);
            });

            return (
                <div className="tab-pane fade" id="usersList" role="tabpanel">
                    <ul className="nav nav-tabs nav-justified nav-sm mt-3" role="tablist" aria-orientation="horizontal">
                        <li className="nav-item">
                            <a className="nav-link text-center active" data-toggle="tab" href="#allUsers" role="tab"
                               aria-controls="direct" aria-selected="true">All</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-center" data-toggle="tab" href="#onlineUsers" role="tab"
                               aria-controls="groups" aria-selected="false">Online</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-center" data-toggle="tab" href="#offlineUsers" role="tab"
                               aria-controls="groups" aria-selected="false">Offline</a>
                        </li>
                    </ul>
                    <hr className="mb-0"/>
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="allUsers" role="tabpanel">
                            {ListUsers(users, onlineUsers)}
                        </div>
                        <div className="tab-pane fade" id="onlineUsers" role="tabpanel">
                            {ListUsers(onlineUsersData, onlineUsers)}
                        </div>
                        <div className="tab-pane fade" id="offlineUsers" role="tabpanel">
                            {ListUsers(offlineUsersData, onlineUsers)}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (null);
        }
    }
}

const ListUsers = (users, onlineUsers) => {

    return Object.keys(users).map(idx => {

        const onlineStatus = onlineUsers.has(users[idx].userId) ? 'online' : 'offline';

        const name = users[idx].fullName ?? users[idx].name;

        return (
            <div key={users[idx].userId} className="d-flex py-5 border-bottom">

                <span className={`avatar avatar-sm status status-${onlineStatus} mr-3 rounded-circle`} style={{background: getPreferredColor(users[idx])}}>
                    {getInitials(name)}
                </span>
                <div className="mr-auto">
                    <h6 className="mb-2 lh-100">{users[idx].fullName}</h6>
                    <p className="lh-100">{users[idx].location}</p>
                </div>

            </div>
        );
    })
};


const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state),
    onlineUsers: getOnlineUsers(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction,
    getOnlineUsers: dispatchOnlineUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Users);