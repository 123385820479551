import { fetchUsersPending, fetchUsersSuccess, fetchUsersError } from "../actions/userActions";
import {API_URL} from "../config";

export default function fetchUsers() {

    return dispatch => {

        dispatch(fetchUsersPending());

        fetch(`${ API_URL }/api/users`)
            .then(r => r.json())
            .then(r => {

                if(r.error)
                    throw r.error;

                dispatch(fetchUsersSuccess(r));

                return r;
            })
            .catch(e => { dispatch(fetchUsersError(e)) });
    };
};