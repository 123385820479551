/*eslint no-useless-constructor: "off"*/
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Users from "./rightSidebarSubComponents/Users";
import Files from "./rightSidebarSubComponents/Files";

class RightSidebar extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        return (
            <div className={`sidebar sidebar-expand-lg sidebar-offcanvas z-index-high vh-100 open`}>
                <div className="container py-5 px-lg-5">
                    <div className="d-flex align-items-center mb-5">
                        <h3 className="mr-auto">
                            Sidebar
                        </h3>
                        <button className="btn btn-sm btn-circle btn-neutral" data-toggle="sidebar-offcanvas" type="button" onClick={this.props.toggle}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="eva eva-close">
                                <g data-name="Layer 2">
                                    <g data-name="close">
                                        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0">
                                        </rect>
                                        <path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z">
                                        </path>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <ul className="nav nav-tabs nav-justified nav-sm mb-5" role="tablist" aria-orientation="horizontal">
                        <li className="nav-item">
                            <a className="nav-link text-center active" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="true">Users</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-center" data-toggle="tab" href="#files" role="tab" aria-controls="files" aria-selected="false">Files</a>
                        </li>
                    </ul>
                    <div className="tab-content">
                        <Users />
                        <Files />
                    </div>
                </div>
            </div>
        );
    }
}

RightSidebar.propTypes = {
    toggle: PropTypes.func
};

export default RightSidebar;
