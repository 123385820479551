import React, { Component } from 'react';
import COLORS from "../../constants/Colors";
import {getActiveChatroom} from "../../reducers/activeChatroom";
import {bindActionCreators} from "redux";
import setActiveChatroom from "../../helpers/GetActiveChatroom";
import {connect} from "react-redux";
import {API_URL} from "../../config";
import activeChatroom from "../../actions/activeChatroomActions";
import store from "../../store";
import socket from "../../socket";

class EditChannel extends Component {

    constructor(props) {

        super(props);

        this.state = {
            channelName: '',
            preferredColor: '',
        };

        this.socket = socket;

        this.handleChange = this.handleChange.bind(this);
        this.saveData = this.saveData.bind(this);
    }

    componentDidMount() {

        const { name, preferredColor } = this.props.activeChatroom.activeChatroom;

        this.setState({
            channelName: name,
            preferredColor
        });
    }

    handleChange(e, field) {

        let value;

        if(field === 'preferredColor')
            value = e;
        else
            value = e.target.value;

        if(field in this.state)
            this.setState({ [field]: value});
    }

    saveData() {

        const { _id } = this.props.activeChatroom.activeChatroom;
        const { channelName, preferredColor } = this.state;

        let data = {};

        if(channelName !== '')
            data['name'] = channelName;
        if(preferredColor !== '')
            data['preferredColor'] = preferredColor;

        fetch(`${ API_URL }/api/rooms/${ _id }/edit`, {
            method: 'PUT',
                headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(r => r.json())
            .then(r => {
                this.socket.emit('updateRoom', r);
                store.dispatch(activeChatroom(r));
            })
            .catch(e => console.log(e));
    }

    render() {
        console.log(this.props.activeChatroom.activeChatroom._id);
        return (
            <div className="modal fade" id="editChatData" tabIndex={-1} role="dialog" aria-labelledby="editChatDataLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="editChatDataLabel">Edit channel</h4>
                            <button className="btn btn-sm btn-circle btn-neutral align-self-start" data-dismiss="modal" type="button" aria-label="Close">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-close" aria-hidden="true"><g data-name="Layer 2"><g data-name="close"><rect width={24} height={24} transform="rotate(180 12 12)" opacity={0} /><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" /></g></g></svg>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="py-5 px-3 px-sm-5">
                                <div className="tab-content">
                                    <label htmlFor="subject">Channel name</label>
                                    <input className="form-control form-control-lg" id="subject" type="text" placeholder="What's the channel name?" value={this.state.channelName} onChange={(e) => this.handleChange(e, 'channelName')} required/>
                                    <br />
                                    <label htmlFor="color">Channel color</label><br />
                                    {
                                        COLORS.map((color, idx) => (
                                            <button className='btn btn-sm btn-circle m-2' key={color} style={{background: color, border: this.state.preferredColor === color ? '5px solid #000' : 'none'}} onClick={() => this.handleChange(color, 'preferredColor')}>
                                                &nbsp;
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-block btn-lg btn-primary" data-dismiss="modal" type="button" aria-label="Close" onClick={this.saveData}>
                                Edit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditChannel);