import React, { Component } from 'react';
import PropTypes from 'prop-types';

import socket from "../../socket";
import store from "../../store";
import ListUsers from "../ListUsers";
import {getOnlineUsers} from "../../reducers/onlineUsers";
import {getActiveChatroom} from "../../reducers/activeChatroom";
import {bindActionCreators} from "redux";
import dispatchOnlineUsers from "../../helpers/GetOnlineUsers";
import setActiveChatroom from "../../helpers/GetActiveChatroom";
import activeChatroom from "../../actions/activeChatroomActions";
import {connect} from "react-redux";
import {getUsers, getUsersError, getUsersPending} from "../../reducers/users";
import fetchUsersAction from "../../helpers/FetchUsers";

const initialState = {
    usersList: [],
    filter: '',
    members: undefined
};

class ListParticipants extends Component {
    constructor(props) {
        super(props);

        this.state = initialState;

        this.update = this.update.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.socket = socket;
    }

    update() {
        const { forUse } = this.props;
        const { activeChatroom } = this.props.activeChatroom;
        const { participantIds } = activeChatroom;
        const { users } = this.props.users;

        const usersNotInChat = users.filter(({userId}) => { return !participantIds.includes(userId); });
        const usersInChat = users.filter(({userId}) => { return participantIds.includes(userId); });

        if( forUse === 'add' ) {

            this.setState({
                usersList: usersNotInChat
            });
        } else if( forUse === 'remove' ) {

            this.setState({
                usersList: usersInChat
            });
        }
    }

    componentDidMount() {
        this.update();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props)
            this.update();
    }

    handleChange(e) {

        e.preventDefault();
        const value = e.target.value;

        this.setState({ filter: value });
    }

    handleSubmit() {

        const { forUse } = this.props;
        const members = Array.from(this.state.members);
        const socketName = forUse === 'add' ? 'addUsersToRoom' : 'removeUsersFromRoom';

        this.socket.emit(socketName, members);

        let activeChatroomData = this.props.activeChatroom.activeChatroom;

        if( forUse === 'add' )
            activeChatroomData.participantIds = [...activeChatroomData.participantIds, ...members];
        else
            activeChatroomData.participantIds = activeChatroomData.participantIds.filter(user => !members.includes(user));

        store.dispatch(activeChatroom(activeChatroomData));

        this.reset();
    }

    handleCheckbox(members) {

        this.setState({members: members});
    }

    reset() {

        this.setState(initialState);
    }

    render() {

        const { forUse } = this.props;

        return (
            <div className="modal fade" id="listParticipants" tabIndex={-1} role="dialog" aria-labelledby="listParticipantsLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="listParticipantsLabel">{forUse === 'add' ? 'Add users to channel' : 'Remove users from channel'}</h4>
                            <button className="btn btn-sm btn-circle btn-neutral align-self-start" data-dismiss="modal" type="button" aria-label="Close" onClick={this.props.handleModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-close" aria-hidden="true"><g data-name="Layer 2"><g data-name="close"><rect width={24} height={24} transform="rotate(180 12 12)" opacity={0} /><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" /></g></g></svg>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <div className="py-5 px-3 px-sm-5">
                                <div className="tab-content">
                                    <form>
                                        <div className="input-group mb-5">
                                            <div className="input-group-prepend">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-funnel-outline"><g data-name="Layer 2"><g data-name="funnel"><rect width={24} height={24} opacity={0} /><path d="M13.9 22a1 1 0 0 1-.6-.2l-4-3.05a1 1 0 0 1-.39-.8v-3.27l-4.8-9.22A1 1 0 0 1 5 4h14a1 1 0 0 1 .86.49 1 1 0 0 1 0 1l-5 9.21V21a1 1 0 0 1-.55.9 1 1 0 0 1-.41.1zm-3-4.54l2 1.53v-4.55A1 1 0 0 1 13 14l4.3-8H6.64l4.13 8a1 1 0 0 1 .11.46z" /></g></g></svg>
                                            </div>
                                            <input className="form-control form-control-lg" type="search" placeholder="Search" aria-label="Search" value={this.state.filter} onChange={(e) => this.handleChange(e)} />
                                        </div>
                                    </form>
                                    <hr className="mb-0" />
                                    <div className="list-users" style={{maxHeight: '250px'}}>
                                        <ListUsers handleCkeckbox={(e) => this.handleCheckbox(e)} checked={this.state.members} filter={this.state.filter} usersList={this.state.usersList} insertMe={false} forUse={this.props.forUse}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-block btn-lg btn-primary" data-dismiss="modal" type="button" aria-label="Close" onClick={this.handleSubmit}>
                                {forUse === 'add' ? 'Add users' : 'Remove users'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ListParticipants.propTypes = {
    forUse: PropTypes.oneOf(['add', 'remove']),
};


const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state),
    onlineUsers: getOnlineUsers(state),
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction,
    getOnlineUsers: dispatchOnlineUsers,
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListParticipants);