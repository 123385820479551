const timeParts = [
    {name: 'millenni', div: 31556736000, p: 'a', s: 'um'},
    {name: 'centur', div: 3155673600, p: 'ies', s: 'y'},
    {name: 'decade', div: 315567360},
    {name: 'year', div: 31556736},
    {name: 'month', div: 2629728},
    {name: 'day', div: 86400},
    {name: 'hour', div: 3600},
    {name: 'minute', div: 60},
    {name: 'second', div: 1}
];

export default function relativeTimeAgo(comparisonDate) {

    let parts = [],
        messageCreatedAt = new Date(comparisonDate).getTime(),
        interval = Math.floor((new Date().getTime() - messageCreatedAt) / 1000);

    for (let i = 0; interval > 0; i++) {

        const value = Math.floor(interval / timeParts[i].div);
        interval = interval - (value * timeParts[i].div);

        if (value) {
            parts.push(value + ' ' + timeParts[i].name + (value !== 1 ? timeParts[i].p || 's' : timeParts[i].s || ''));
        }
    }
    if (parts.length === 0) {
        return 'Now';
    }
    return parts[0] + ' ago';
};