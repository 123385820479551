
import React, { Component } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserAltSlash} from "@fortawesome/free-solid-svg-icons/faUserAltSlash";
import socket, {userId} from "../../../socket";
import PropTypes from 'prop-types';
import {getUsers, getUsersError, getUsersPending} from "../../../reducers/users";
import {getOnlineUsers} from "../../../reducers/onlineUsers";
import {bindActionCreators} from "redux";
import fetchUsersAction from "../../../helpers/FetchUsers";
import dispatchOnlineUsers from "../../../helpers/GetOnlineUsers";
import {connect} from "react-redux";
import {getActiveChatroom} from "../../../reducers/activeChatroom";
import setActiveChatroom from "../../../helpers/GetActiveChatroom";
import getInitials from "../../../helpers/Initials";
import getPreferredColor from "../../../helpers/GetPreferredColor";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import store from "../../../store";
import activeChatroom from "../../../actions/activeChatroomActions";

class Users extends Component {

    constructor(props) {
        super(props);

        this.state = {
            members: []
        };

        this.socket = socket;

        this.loadParticipants = this.loadParticipants.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    loadParticipants() {

        let members = Array.from(this.props.activeChatroom.activeChatroom.participantIds);

        this.setState({ members });
    }

    componentDidMount() {

        this.loadParticipants();

        this.socket.on('addRoomToList', (data) => {
            this.setState({ members: data.participantIds });
        });

    }

    removeUser(id) {

        this.socket.emit('removeUsersFromRoom', [id]);

        this.setState(prevState => ({
            members: prevState.members.filter(member => { return member !== id })
        }));

        if(id === userId)
            store.dispatch(activeChatroom(null));
    }

    componentWillUnmount() {
        this.socket.off('addRoomToList');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.activeChatroom && prevProps.activeChatroom.activeChatroom !== this.props.activeChatroom.activeChatroom) {
            this.loadParticipants();
        }
    }

    render() {

        let { users } = this.props.users;

        let usersArray = {};
        for( const user of users )
            usersArray[user.userId] = user;
        users = usersArray;

        return (
            <div className="tab-pane fade active show" id="users" role="tabpanel">
                <hr className="my-0" />
                {
                    this.state.members.map(idx => (
                        <div key={idx} className="d-flex py-5 border-bottom">
                            <span className="avatar avatar-sm status status-online mr-3 rounded-circle" style={{background: getPreferredColor(users[idx])}}>
                                {getInitials(users[idx].fullName)}
                            </span>
                            <div className="mr-auto">
                                <h6 className="mb-2 lh-100">{users[idx].fullName}</h6>
                                <p className="lh-100">{users[idx].location}</p>
                            </div>
                            <div className="dropdown align-self-center ml-3">
                                <button className="btn" data-toggle="dropdown" type="button" aria-haspopup="true"
                                        aria-expanded="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                         className="eva eva-more-vertical">
                                        <g data-name="Layer 2">
                                            <g data-name="more-vertical">
                                                <rect width={24} height={24} transform="rotate(-90 12 12)" opacity={0}/>
                                                <circle cx={12} cy={12} r={2}/>
                                                <circle cx={12} cy={5} r={2}/>
                                                <circle cx={12} cy={19} r={2}/>
                                            </g>
                                        </g>
                                    </svg>
                                    <span className="sr-only">Toggle Dropdown</span>
                                </button>
                                {
                                    users[idx].userId === userId ?
                                        (
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button" onClick={() => this.removeUser(users[idx].userId)}>
                                                    <div className="btn btn-sm btn-circle btn-neutral mr-3">
                                                        <FontAwesomeIcon icon={faSignOutAlt} />
                                                    </div>
                                                    Leave chat
                                                </button>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="dropdown-menu dropdown-menu-right">
                                                <button className="dropdown-item" type="button" onClick={() => this.removeUser(users[idx].userId)}>
                                                    <div className="btn btn-sm btn-circle btn-neutral mr-3">
                                                        <FontAwesomeIcon icon={faUserAltSlash} />
                                                    </div>
                                                    Remove from chat
                                                </button>
                                            </div>
                                        )
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

Users.propTypes = {
    participantIds: PropTypes.array
};


const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state),
    onlineUsers: getOnlineUsers(state),
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction,
    getOnlineUsers: dispatchOnlineUsers,
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Users);
