import { FETCH_USERS_ERROR, FETCH_USERS_PENDING, FETCH_USERS_SUCCESS } from "../constants/ActionTypes";

export function fetchUsersPending() {
    return {
        type: FETCH_USERS_PENDING
    };
}

export function fetchUsersSuccess(users) {
    return {
        type: FETCH_USERS_SUCCESS,
        users
    };
}

export function fetchUsersError(error) {
    return {
        type: FETCH_USERS_ERROR,
        error
    };
}