import React, { Component } from 'react';
import SortedSet from 'js-sorted-set';
import {CircleSpinner} from "react-spinners-kit";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import PropTypes from 'prop-types';

import CreateChannel from "./channelSubComponents/CreateChannel";
import store from "../../store";
import activeChatroom from "../../actions/activeChatroomActions";
import socket, {userId} from "../../socket";
import relativeTimeAgo from "../../helpers/RelativeTimeAgo";
import compareRooms from "../../helpers/RoomsCmp";
import {API_URL} from "../../config";
import getPreferredColor from "../../helpers/GetPreferredColor";
import {AUDIO} from "../../constants/CallModalConstants";
import {getActiveChatroom} from "../../reducers/activeChatroom";
import setActiveChatroom from "../../helpers/GetActiveChatroom";

class Channels extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rooms: new SortedSet({
                strategy: SortedSet.RedBlackTreeStrategy,
                comparator: compareRooms
            }),
            active: 0,
            showModal: false,
            roomsMap: new Map(),
            loading: false
        };

        this.handleModal = this.handleModal.bind(this);

        this.socket = socket;
    }

    handleModal() {
        this.setState({
            showModal: !this.state.showModal
        });
    }

    handleClick(e, room) {

        const { _id } = room;
        this.setState({active: _id});
        store.dispatch(activeChatroom(room));
        this.socket.emit('switchRoom', _id);

        let roomsMap = this.state.roomsMap;
        let roomData = roomsMap.get(_id);

        roomsMap.delete(_id);
        roomData.newMessage = false;
        roomsMap.set(_id, roomData);

        this.setState({roomsMap: roomsMap});

        socket.emit('seenMessage', userId, _id);
    }

    getActiveChatroom() {

        if(this.props.activeChatroom && this.props.activeChatroom.activeChatroom && '_id' in this.props.activeChatroom.activeChatroom)
            return this.props.activeChatroom.activeChatroom._id;
        else
            return null;
    }

    getRooms() {

        let self = this;

        this.setState({ loading: true }, () => {

            fetch(`${API_URL}/api/rooms/user/${userId}`)
                .then(rooms => rooms.json())
                .then( async rooms => {
                    return Promise.all(rooms.map(room => {

                        const lastMessageId = [...(room.messages)].pop();

                        return fetch(`${API_URL}/api/messages/${lastMessageId}`)
                            .then(message => message.json())
                            .then(message => {

                                let lastMessage;

                                if(!message)
                                    lastMessage = '';
                                else if (message.message)
                                    lastMessage = message.message;
                                else if (message.mediaCall)
                                    lastMessage = message.mediaCall === AUDIO ? 'Audio call...' : 'Video call...';
                                else if (message.file)
                                    lastMessage = 'Attachment...';

                                return {
                                    ...room,
                                    lastMessage
                                };
                            })
                            .catch(e => console.log(e));;
                    }))
                })
                .then(async rooms => {
                    return Promise.all(rooms.map(room => {

                        return fetch(`${API_URL}/api/seen/room/${room._id}/user/${userId}`)
                            .then(seen => seen.json())
                            .then(seen => {

                                let newMessage;

                                if(seen.length === 0) {
                                    newMessage = true;
                                } else {

                                    const seenTimestamp = Date.parse(seen.lastEvent);
                                    const messageTimestamp = Date.parse(room.lastMessage.createdAt);

                                    newMessage = seenTimestamp < messageTimestamp;
                                }
                                return {
                                    ...room,
                                    newMessage
                                }
                            })
                            .catch(e => console.log(e));;
                    }));
                })
                .then(rooms => {

                    let roomsSet =
                            new SortedSet({
                                strategy: SortedSet.RedBlackTreeStrategy,
                                comparator: compareRooms
                            }),
                        roomsMap = new Map();

                    for(let idx in rooms) {

                        roomsSet.insert(rooms[idx]);
                        roomsMap.set(rooms[idx]._id, rooms[idx]);
                    }

                    self.setState({
                        rooms: roomsSet,
                        roomsMap: roomsMap,
                        loading: false
                    });
                })
                .catch(e => console.log(e));
        });
    };

    componentDidMount() {
        this.getRooms();

        this.socket.on('messageNotification', (roomId, message) => {

            let roomsMap = this.state.roomsMap;
            let roomData = roomsMap.get(roomId);
            let rooms = this.state.rooms;

            rooms.remove(roomData);
            roomsMap.delete(roomId);

            roomData.updatedAt = new Date().toISOString();

            rooms.insert(roomData);

            roomData.newMessage = roomId !== this.getActiveChatroom();

            if(message.message)
                roomData.lastMessage = message.message;
            else if(message.mediaCall)
                roomData.lastMessage = message.mediaCall === AUDIO ? 'Audio call...' : 'Video call...';
            else if(message.file)
                roomData.lastMessage = message.file;
            else roomData.lastMessage = '';

            roomsMap.set(roomId, roomData);

            this.setState({
                rooms: rooms,
                roomsMap: roomsMap
            });
        });

        this.socket.on('addRoomToList', (data) => {

            let roomsMap = this.state.roomsMap;
            let rooms = this.state.rooms;

            if(roomsMap.get(data._id)) {

                const mapData = roomsMap.get(data._id);

                rooms.remove(mapData);
                roomsMap.delete(data._id);
            }

            rooms.insert(data);
            roomsMap.set(data._id, data);

            this.setState({
                rooms: rooms,
                roomsMap: roomsMap
            });
        });

        this.socket.on('updateRoom', (data) => {

            let roomsMap = this.state.roomsMap;
            let rooms = this.state.rooms;

            if(roomsMap.get(data._id)) {

                const mapData = roomsMap.get(data._id);

                rooms.remove(mapData);
                roomsMap.delete(data._id);
            }

            rooms.insert(data);
            roomsMap.set(data._id, data);

            this.setState({
                rooms: rooms,
                roomsMap: roomsMap
            });
        });

        this.socket.on('removeRoomFromList', (roomId) => {

            let roomsMap = this.state.roomsMap;
            let rooms = this.state.rooms;

            const roomData = roomsMap.get(roomId);

            rooms.remove(roomData);
            roomsMap.delete(roomId);

            this.setState({
                rooms: rooms,
                roomsMap: roomsMap
            });

            if(this.props.activeChatroom && this.props.activeChatroom.activeChatroom && this.props.activeChatroom.activeChatroom._id === roomId)
                store.dispatch(activeChatroom(null));
        });
    }

    componentWillUnmount() {
        this.socket.off('messageNotification');
        this.socket.off('addRoomToList');
        this.socket.off('removeRoomFromList');
    }

    render() {

        let rooms = this.state.rooms;

        if(this.props.filter !== '') {

            const filter = this.props.filter.toLowerCase();

            rooms = this.state.rooms.map((room) => {
                if(room.name.toLowerCase().includes(filter))
                    return room;
                else return undefined;
            }).filter((el) => { return el !== undefined });
        }


        let direct = [], groups = [];

        rooms.forEach((roomData) => {

            const { _id } = roomData;

            let { name, participantIds, updatedAt, newMessage, lastMessage } = this.state.roomsMap.get(_id);

            if(typeof lastMessage === 'object') {
                lastMessage = lastMessage.message;
            }

            let lastMessagePrint = (newMessage && _id !== this.getActiveChatroom()) ? `<strong>${lastMessage}</strong>` : lastMessage;
            const conversationType = participantIds.length <= 2 ? 'direct' : 'group';

            const room = (
                <div key={_id} className={`channel ${_id === this.state.active ? 'active' : ''}`} onClick={(e) => this.handleClick(e, roomData)}>
                    <span className={`avatar avatar-sm status mr-3 rounded-circle`} style={{background: getPreferredColor(roomData)}}>{name[0].toUpperCase()}
                    </span>
                    <div className="flex-grow-1">
                        <div className="d-flex align-items-center mb-3 channelName">
                            <h6 className="mr-auto">{name}</h6>
                        </div>
                        <p className="text" dangerouslySetInnerHTML={{ __html: lastMessagePrint }} />
                        <div className="d-flex align-items-center mt-3">
                            <p className="text">{relativeTimeAgo(updatedAt)}</p>
                        </div>

                    </div>
                </div>
            );

            if(conversationType === 'direct')
                direct = [...direct, room];
            else
                groups = [...groups, room];

        });

        if(this.state.loading) {
            return (
                <div className="tab-pane fade active show" id="channels" role="tabpanel">
                    <ul className="nav nav-tabs nav-justified nav-sm mt-3" role="tablist" aria-orientation="horizontal">
                        <li className="nav-item">
                            <a className="nav-link text-center active" data-toggle="tab" href="#direct" role="tab"
                               aria-controls="direct" aria-selected="true">Direct</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-center" data-toggle="tab" href="#groups" role="tab"
                               aria-controls="groups" aria-selected="false">Groups</a>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center mt-5">
                        <h3 className="mr-3">Channels</h3>
                        <button className="btn btn-sm btn-circle btn-neutral" data-toggle="modal" data-target="#compose"
                                type="button" onClick={this.handleModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24"
                                 className="eva eva-edit-2">
                                <g data-name="Layer 2">
                                    <g data-name="edit-2">
                                        <rect width={24} height={24} opacity={0}/>
                                        <path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2z"/>
                                        <path
                                            d="M5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4L18 6.73l-2 1.95L13.32 6z"/>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <hr className="mb-0"/>
                    <div className="tab-content">
                        <div className="tab-pane fade active show d-flex" id="direct" role="tabpanel" style={{alignItems: 'center', justifyContent: 'center'}}>
                            <CircleSpinner color={`#007BFF`} />
                        </div>
                        <div className="tab-pane fade d-flex" id="groups" role="tabpanel" style={{alignItems: 'center', justifyContent: 'center'}}>
                            <CircleSpinner color={`#007BFF`} />
                        </div>
                    </div>

                    <CreateChannel handleModal={this.handleModal} showModal={this.state.showModal}/>
                </div>
            );
        } else {
            return (
                <div className="tab-pane fade active show" id="channels" role="tabpanel">
                    <ul className="nav nav-tabs nav-justified nav-sm mt-3" role="tablist" aria-orientation="horizontal">
                        <li className="nav-item">
                            <a className="nav-link text-center active" data-toggle="tab" href="#direct" role="tab"
                               aria-controls="direct" aria-selected="true">Direct</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link text-center" data-toggle="tab" href="#groups" role="tab"
                               aria-controls="groups" aria-selected="false">Groups</a>
                        </li>
                    </ul>
                    <div className="d-flex align-items-center mt-5">
                        <h3 className="mr-3">Channels</h3>
                        <button className="btn btn-sm btn-circle btn-neutral" data-toggle="modal" data-target="#compose"
                                type="button" onClick={this.handleModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24"
                                 className="eva eva-edit-2">
                                <g data-name="Layer 2">
                                    <g data-name="edit-2">
                                        <rect width={24} height={24} opacity={0}/>
                                        <path d="M19 20H5a1 1 0 0 0 0 2h14a1 1 0 0 0 0-2z"/>
                                        <path
                                            d="M5 18h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71L16.66 2.6A2 2 0 0 0 14 2.53l-9 9a2 2 0 0 0-.57 1.21L4 16.91a1 1 0 0 0 .29.8A1 1 0 0 0 5 18zM15.27 4L18 6.73l-2 1.95L13.32 6z"/>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <hr className="mb-0"/>
                    <div className="tab-content">
                        <div className="tab-pane fade active show" id="direct" role="tabpanel">
                            {direct}
                        </div>
                        <div className="tab-pane fade" id="groups" role="tabpanel">
                            {groups}
                        </div>
                    </div>

                    <CreateChannel handleModal={this.handleModal} showModal={this.state.showModal}/>
                </div>

            );
        }
    }
}

const mapStateToProps = (state) => ({
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Channels);