import React, { Component } from 'react';
import store from "../store";

import Header from "./mainSubComponents/Header";
import Body from "./mainSubComponents/Body";
import Footer from "./mainSubComponents/Footer";
import RightSidebar from "./mainSubComponents/RightSidebar";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { HIDE } from "../constants/CallModalConstants";
import Media from "./headerSubComponents/Media";
import {getActiveChatroom} from "../reducers/activeChatroom";
import setActiveChatroom from "../helpers/GetActiveChatroom";

class Main extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toggle: true,
            active: 0,
            showMedia: HIDE
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleModal = this.handleModal.bind(this);

    }

    handleClick(e) {

        e.preventDefault();

        const newState = !this.state.toggle;
        this.setState({toggle: newState});
    }

    handleModal(value) {

        this.setState({ showMedia: value });
    }


    render() {

        const { activeChatroom } = this.props;

        return (
            <div className="flex-lg-grow-1">
                <div className="chat chat-offcanvas">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <div className="container px-lg-5">
                                {
                                    activeChatroom === null || activeChatroom.activeChatroom === null ?
                                    (
                                        <Body />
                                    ) :
                                    (
                                        <div>
                                            <Header onHandleModal={this.handleModal} />
                                            <Body onHandleModal={this.handleModal} />
                                            <Footer />
                                        </div>
                                    )
                                }
                                {
                                    <div>
                                        <Media show={this.state.showMedia} activeChatroom={store.getState().activeChatroom} onHandleModal={this.handleModal}/>
                                    </div>
                                }
                            </div>
                            {
                                activeChatroom !== null && activeChatroom.activeChatroom !== null &&
                                (
                                <button className="btn btn-circle btn-neutral sidebar-toggler" data-toggle="sidebar-offcanvas" onClick={this.handleClick}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="eva eva-chevron-left ml-n3">
                                        <g data-name="Layer 2">
                                            <g data-name="chevron-left">
                                                <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0">

                                                </rect>
                                                <path d="M13.36 17a1 1 0 0 1-.72-.31l-3.86-4a1 1 0 0 1 0-1.4l4-4a1 1 0 1 1 1.42 1.42L10.9 12l3.18 3.3a1 1 0 0 1 0 1.41 1 1 0 0 1-.72.29z">

                                                </path>
                                            </g>
                                        </g>
                                    </svg>
                                </button>
                                )
                            }
                        </div>

                        {this.state.toggle && activeChatroom && activeChatroom.activeChatroom && <RightSidebar toggle={this.handleClick} />}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Main);

