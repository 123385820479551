const extensions = {
    audio: ['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma', 'wpl'],
    archive: ['7z', 'arj', 'deb', 'pkg', 'rar', 'rpm', 'gz', 'z', 'zip'],
    image: ['ai', 'bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'ps', 'psd', 'svg', 'tif', 'tiff'],
    text: ['doc', 'docx', 'odt', 'pdf', 'rtf', 'tex', 'txt', 'wpd', 'ods', 'xls', 'xlsm', 'xlsx', 'key', 'odp', 'pps', 'ppt', 'pptx', 'csv', 'dat', 'db', 'dbf', 'log', 'mdb', 'sav', 'sql', 'tar', 'xml'],
    video: ['3g2', '3gp', 'avi', 'flv', 'h264', 'm4v', 'mkv', 'mov', 'mp4', 'mpg', 'mpeg', 'rm', 'swf', 'wmv']

};

export default function getIcon(extension) {

    for(const [key, value] of Object.entries(extensions)) {

        if(value.includes(extension))
            return key;
    }
}