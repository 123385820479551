import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { userId as USER_ID } from "../socket";
import getInitials from '../helpers/Initials';
import {getUsers, getUsersError, getUsersPending} from "../reducers/users";
import {getOnlineUsers} from "../reducers/onlineUsers";
import {bindActionCreators} from "redux";
import fetchUsersAction from "../helpers/FetchUsers";
import dispatchOnlineUsers from "../helpers/GetOnlineUsers";
import {connect} from "react-redux";
import getPreferredColor from "../helpers/GetPreferredColor";

class ListUsers extends Component {

    constructor(props) {

        super(props);

        this.state = {
            members: new Set(this.props.insertMe ? [USER_ID] : [])
        };

        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleReset = this.handleReset.bind(this);

        this.initialState = {
            members: new Set(this.props.insertMe ? [USER_ID] : [])
        };
    }

    handleReset() {
        this.setState(this.initialState);
    }

    handleCheckbox(e) {

        let { name } = e.target;
        name = parseInt(name);
        let membersSet = this.state.members;

        if(membersSet.has(name))
            membersSet.delete(name);
        else
            membersSet.add(name);

        this.setState({ members: membersSet });

        this.props.handleCkeckbox(this.state.members);
    }

    render() {

        const { users } = this.props.users;
        const { onlineUsers } = this.props.onlineUsers;

        const usersFromProps = this.props.usersList ?? users;

        let usersList = usersFromProps.map(user => {

            const { userId } = user.userId;

            if(userId !== USER_ID)
                return user;
            else return undefined;
        }).filter((user) => {return user !== undefined});

        if(this.props.filter) {

            const filter = this.props.filter;

            usersList = usersList.filter(({fullName}) => {return fullName.toLowerCase().includes(filter)});
        }

        return usersList.map(userEntity => {

            const {_id, userId, fullName, location} = userEntity;

            if(userId !== USER_ID || this.props.insertMe === false) {
                return (
                    <div key={_id} className="d-flex py-5 border-bottom">

                        <span
                            className={`avatar avatar-sm status status-${onlineUsers.has(userId) ? 'online' : 'offline'} mr-3 rounded-circle`} style={{background: getPreferredColor(userEntity)}}>
                            {getInitials(fullName)}
                        </span>
                        <div className="mr-auto">
                            <h6 className="mb-2 lh-100">{fullName}</h6>
                            <p className="lh-100">{location}</p>
                        </div>
                        <div className="custom-control custom-checkbox align-self-center ml-3">
                            <input className="custom-control-input" id={`id_${userId}_${this.props.forUse}`} name={userId} type="checkbox"
                                   onClick={(e) => this.handleCheckbox(e)}/>
                            <label className="custom-control-label" htmlFor={`id_${userId}_${this.props.forUse}`}/>
                        </div>
                    </div>
                );
            }
            else return undefined;
        }).filter(el => { return el !== undefined });
    }
}

ListUsers.propTypes = {
    handleCheckbox: PropTypes.func,
    checked: PropTypes.object,
    filter: PropTypes.string,
    insertMe: PropTypes.bool
};

const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state),
    onlineUsers: getOnlineUsers(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction,
    getOnlineUsers: dispatchOnlineUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
