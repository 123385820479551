const COLORS = [
    'hsl(211,100%,50%)',
    'hsl(263,90%,51%)',
    'hsl(261,51%,51%)',
    'hsl(332,79%,58%)',
    'hsl(354,70%,54%)',
    'hsl(27,98%,54%)',
    'hsl(45,100%,51%)',
    'hsl(134,61%,45%)',
    'hsl(162,73%,46%)',
    'hsl(188,78%,45%)'
];

export default COLORS;