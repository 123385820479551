import React, { Component } from 'react';

import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Main from "./components/Main";
import {getUsers, getUsersError, getUsersPending} from "./reducers/users";
import {bindActionCreators} from "redux";
import fetchUsersAction from "./helpers/FetchUsers";
import {connect} from "react-redux";
import socket from "./socket";
import store from "./store";
import onlineUsers from "./actions/onlineUsersActions";
import {getOnlineUsers} from "./reducers/onlineUsers";
import dispatchOnlineUsers from "./helpers/GetOnlineUsers";
import {fetchUsersSuccess} from "./actions/userActions";

class App extends Component {

    constructor(props) {
        super(props);

        this.socket = socket;
    }


    componentDidMount() {

        const { fetchUsers } = this.props;
        fetchUsers();

        this.socket.on('updateUserSettings', (usersData) => {
            store.dispatch(fetchUsersSuccess(usersData));
        });

        this.socket.on('updateUsers', (onlineUsersData) => {

            let onlineUserIds = new Set();

            for(const user of Object.values(onlineUsersData))
                onlineUserIds.add(user.id);

            store.dispatch(onlineUsers(onlineUserIds));
        });
    }

    componentWillUnmount() {
        this.socket.off('updateUserSettings');
        this.socket.off('updateUsers');
    }

    shouldComponentRender() {

        const { pending } = this.props;
        return pending !== false;
    }

    render() {

        if (this.shouldComponentRender()) {

            return (
                <div className="App">
                    <div className="d-flex flex-column flex-lg-row">

                        <Navbar/>
                        <Sidebar/>
                        <Main/>
                    </div>
                </div>
            );
        } else return (null);
    }
}

const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state),
    getOnlineUsers: getOnlineUsers(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction,
    onlineUsers: dispatchOnlineUsers
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
