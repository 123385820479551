import { UPDATE_ONLINE_USERS } from '../constants/ActionTypes';

const initialState = {
    onlineUsers: new Set()
};

export function onlineUsersReducer(state = initialState, action) {

    switch(action.type) {
        case UPDATE_ONLINE_USERS:
            return {
                ...state,
                onlineUsers: action.onlineUsers
            };
        default:
            return state;
    }
}

export const getOnlineUsers = state => state.onlineUsers;