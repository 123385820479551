import React, { Component } from 'react';
import Icon from 'react-eva-icons';

import socket, { userId } from "../../../socket";
import getInitials from "../../../helpers/Initials";
import getIcon from "../../../helpers/FileIconName";
import {API_URL} from "../../../config";
import {getActiveChatroom} from "../../../reducers/activeChatroom";
import {bindActionCreators} from "redux";
import fetchUsersAction from "../../../helpers/FetchUsers";
import setActiveChatroom from "../../../helpers/GetActiveChatroom";
import {connect} from "react-redux";
import {getUsers, getUsersError, getUsersPending} from "../../../reducers/users";
import getPreferredColor from "../../../helpers/GetPreferredColor";
import getShortFileName from "../../../helpers/GetShortFileName";
import getFileSize from "../../../helpers/GetFileSize";

class Files extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            files: [],
            inputKey: 0,
            fileName: null
        };

        this.socket = socket;
        this.uploadInput = React.createRef();

        this.handleUpload = this.handleUpload.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    loadFiles() {

        const { _id } = this.props.activeChatroom.activeChatroom;

        fetch(`${API_URL}/api/file/room/${_id}/files`)
            .then(files => files.json())
            .then(files => this.setState({files}))
            .catch(error => console.log(error));

    }

    handleChange() {

        if(this.uploadInput.current.files)
            this.setState({fileName: this.uploadInput.current.files[0].name});
    }

    componentDidMount() {
        this.loadFiles();
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.activeChatroom && prevProps.activeChatroom.activeChatroom !== this.props.activeChatroom.activeChatroom) {
            this.loadFiles();
        }
    }

    handleUpload(e) {

        e.preventDefault();

        const { _id } = this.props.activeChatroom.activeChatroom;

        const data = new FormData();
        data.append('userId', userId);
        data.append('file', this.uploadInput.current.files[0]);

        const options = {
            method: 'POST',
            body: data,
            headers: {
                'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryIn312MOjBWdkffIM'
            }
        };

        delete options.headers['Content-Type'];

        fetch(`${API_URL}/api/file/room/${_id}`, options)
            .then(res => res.json())
            .then(res => {
                this.socket.emit('sendChat', {file: res.file, fileSize: res.fileSize}, userId);
                this.setState(prevState => ({
                    files: [res, ...prevState.files],
                    fileName: null
                }));
            })
            .catch(error => console.error(`Error uploading files: ${error}`));
    }

    render() {

        let { users } = this.props.users;

        let usersArray = {};
        for( const user of users )
            usersArray[user.userId] = user;
        users = usersArray;

        let listFiles = [];
        [...this.state.files].forEach(file => {
            listFiles = [...listFiles, (<FileCard fileData={file} userData={users[file.userId]} key={file._id}/>)]
        });

        return (
            <div className="tab-pane fade" id="files" role="tabpanel">
                <label className="w-100">
                    <form onSubmit={(e) => this.handleUpload(e)}>
                        <input className="d-none" type="file" ref={this.uploadInput} onChange={this.handleChange}
                               required disabled={this.state.fileName ? 'disabled' : ''}/>
                        <span className="dropzone">{
                            this.state.fileName ?
                                <div>
                                    {this.state.fileName}
                                    <button style={{all: 'unset'}}>
                                        <span className="icon bg-success border rounded-circle ml-1">
                                            <Icon
                                                name="cloud-upload-outline"
                                                size="medium"
                                            />
                                        </span>
                                    </button>
                                </div>
                                :
                                'Drag & drop files here'
                        }</span>
                    </form>
                </label>
                <div id="filesList">
                    {
                        listFiles
                    }
                </div>
            </div>

        );
    }
}

function FileCard({fileData, userData}) {

    const { file } = fileData;

    const lastDotIdx = file.lastIndexOf('.');
    const extension = file.substr(lastDotIdx+1);

    const fileSize = getFileSize(fileData.fileSize[0]);

    return (
        <div className="card mb-3">
            <div className="card-body d-flex align-items-center">
          <span className="icon bg-neutral border rounded-circle">
            <Icon
                name={getIcon(extension)}
                size="medium"
            />
          </span>
                <span className="avatar avatar-sm mr-3 ml-n3 border rounded-circle" style={{background: getPreferredColor(userData)}}>
                {getInitials(userData.fullName)}
            </span>
                <div className="mr-auto">
                    <h6 className="mb-2 lh-100">
                        <a href={`${API_URL}/api/file/${file}`}>{getShortFileName(file)}</a>
                    </h6>
                    <p className="lh-100">{fileSize}</p>
                </div>
                {/*<div className="dropdown">
                    <button className="btn" data-toggle="dropdown" type="button" aria-haspopup="true" aria-expanded="false">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-more-vertical"><g data-name="Layer 2"><g data-name="more-vertical"><rect width={24} height={24} transform="rotate(-90 12 12)" opacity={0} /><circle cx={12} cy={12} r={2} /><circle cx={12} cy={5} r={2} /><circle cx={12} cy={19} r={2} /></g></g></svg>
                        <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <div className="dropdown-menu dropdown-menu-right" x-placement="bottom-end" style={{position: 'absolute', willChange: 'transform', top: '0px', left: '0px', transform: 'translate3d(0px, 5px, 0px)'}} x-out-of-boundaries="true">
                        <button className="dropdown-item" type="button">Action</button>
                        <button className="dropdown-item" type="button">Another action</button>
                    </div>
                </div>*/}
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state),
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction,
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Files);
