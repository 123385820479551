import React, { Component } from 'react';
import ScrollableFeed from 'react-scrollable-feed';
import { StageSpinner } from "react-spinners-kit";
import PropTypes from 'prop-types';
import Icon from 'react-eva-icons';
import TrackVisibility from 'react-on-screen';

import socket, { userId as USER_ID } from "../../socket";
import relativeTimeAgo from "../../helpers/RelativeTimeAgo";
import getInitials from "../../helpers/Initials";
import FaCommentsO from 'react-icons/lib/fa/comments-o';
import getIcon from "../../helpers/FileIconName";
import { API_URL, MESSAGES_IN_BUCKET } from "../../config";
import {AUDIO, VIDEO} from "../../constants/CallModalConstants";
import {getUsers, getUsersError, getUsersPending} from "../../reducers/users";
import {getOnlineUsers} from "../../reducers/onlineUsers";
import {bindActionCreators} from "redux";
import fetchUsersAction from "../../helpers/FetchUsers";
import dispatchOnlineUsers from "../../helpers/GetOnlineUsers";
import {connect} from "react-redux";
import {getActiveChatroom} from "../../reducers/activeChatroom";
import setActiveChatroom from "../../helpers/GetActiveChatroom";
import getPreferredColor from "../../helpers/GetPreferredColor";
import Pair from "../../helpers/Pair";
import getFileSize from "../../helpers/GetFileSize";

class Body extends Component {

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            messages: [],
            typing: 0,
            read: new Pair(0, 0)
        };

        this.getMoreMessages = this.getMoreMessages.bind(this);

        this.socket = socket;
    }

    async fetchData() {

        if( this.props.activeChatroom && this.props.activeChatroom.activeChatroom ) {

            const { _id } = this.props.activeChatroom.activeChatroom;

            await fetch(`${API_URL}/api/messages/room/${_id}`)
                .then(res => res.json())
                .then(res => this.setState({
                    messages: res,
                    read: new Pair(Math.max(res.length - MESSAGES_IN_BUCKET, 0), res.length)
                }))
                .catch(e => console.log(e));

            const scrollToBottomElement = document.getElementById('scrollToBottom');

            if(scrollToBottomElement)
                scrollToBottomElement.scrollIntoView();
        }
    };

    async componentDidMount() {

        this._isMounted = true;
        this.fetchData();

        this.socket.on('updateChat', (data) => {
            this.addMessage(data);
        });

        this.socket.on('userTyping', () => {
            this.setState(prevState => ({ typing: prevState.typing + 1 }));
        });

        this.socket.on('userStopTyping', () => {
            this.setState(prevState => ({ typing: prevState.typing - 1 }));
        });

        this.socket.on('mediaCall', (data) => {
            this.addMessage(data);
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if(prevProps.activeChatroom !== this.props.activeChatroom)
            this.fetchData();

        else if(prevState.read.first && prevState.read.first !== this.state.read.first)
            if(document.getElementById(this.state.read.first + MESSAGES_IN_BUCKET))
                document.getElementById(this.state.read.first + MESSAGES_IN_BUCKET).scrollIntoView();
    }

    componentWillUnmount() {
        this._isMounted = false;

        this.socket.off('updateChat');
        this.socket.off('userTyping');
        this.socket.off('userStopTyping');
        this.socket.off('mediaCall');
    }

    addMessage(message) {

        const lastMessage = this.state.messages ? this.state.messages.slice(-1)[0] : null;

        if(lastMessage !== message)
            this.setState({
                messages: [...this.state.messages, message],
                read: new Pair(this.state.read.first, this.state.read.second+1)
            });
    }

    getMoreMessages() {

        this.setState({
            read: new Pair(Math.max(this.state.read.first - MESSAGES_IN_BUCKET, 0), this.state.read.second)
        });
    }

    render() {

        let { users, activeChatroom } = this.props;
        const { first, second } = this.state.read;

        if(activeChatroom === null || activeChatroom.activeChatroom === null) {
            return (
                <div className="chat-body" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
                    <div style={{display: 'block', textAlign: 'center'}}>
                        <FaCommentsO size="5em" />
                        <p className="mt-5">Select a chat to read messages</p>
                    </div>
                </div>
            )
        } else {

            users = users.users;

            let usersArray = {};
            for( const user of users )
                usersArray[user.userId] = user;
            users = usersArray;

            return (
                <div className="chat-body" onLoad={this.scrollElement}>
                    <ScrollableFeed className="scrollableMessages" forceScroll={false}>

                        <div className="d-flex align-items-center justify-content-center mb-3 mt-3">
                            {
                                this.state.read.first > 0 ? (<button className='btn btn-neutral btn-sm ' onClick={this.getMoreMessages}>Load more</button>) : 'No more messages.'
                            }
                        </div>


                        {this.state.messages.slice(first, second).map((tmpMessage, idx) => {

                            const { userId, message, createdAt, file, mediaCall, fileSize } = tmpMessage;
                            idx += first;

                            if(message) {
                                if(USER_ID === userId)
                                    return (
                                        <div key={idx} id={idx}
                                             className="d-flex align-items-end justify-content-end mb-5 mt-5">
                                            <span className="avatar avatar-sm order-2 ml-3 rounded-circle"
                                                  data-toggle="tooltip" data-placement="bottom"
                                                  title={users[userId].fullName}
                                                  style={{background: getPreferredColor(users[userId])}}>
                                                {getInitials(users[userId].fullName)}
                                            </span>
                                            <div className="order-1">
                                                <div className="card mb-3 text-white" style={{
                                                    display: 'inline-block',
                                                    wordWrap: 'break-word',
                                                    background: getPreferredColor(users[userId])
                                                }}>
                                                    {message}
                                                </div>
                                                <p className="lh-100 text-right">{relativeTimeAgo(createdAt)}</p>
                                            </div>
                                        </div>
                                    );
                                else
                                    return (
                                        <div key={idx} id={idx} className="d-flex align-items-end mb-5 mt-5">
                                        <span className="avatar avatar-sm mr-3 rounded-circle" data-toggle="tooltip"
                                              data-placement="bottom" title={users[userId].fullName}
                                              style={{background: getPreferredColor(users[userId])}}>
                                            {getInitials(users[userId].fullName)}
                                        </span>
                                            <div>
                                                <div className="card mb-3"
                                                     style={{display: 'inline-block', wordWrap: 'break-word'}}>
                                                    {message}
                                                </div>
                                                <p className="lh-100">{relativeTimeAgo(createdAt)}</p>
                                            </div>
                                        </div>
                                    );
                            }

                            if(mediaCall)
                                return (
                                    <div key={idx} id={idx} className="d-flex align-items-center justify-content-center">
                                        <div className="order-1">
                                            <div className="card mb-3">

                                                <div className="card-body">
                                                    <h5 className="card-title">
                                                        <div style={{textAlign: 'center'}}>
                                                                <span className="text-capitalize">
                                                                    {mediaCall === AUDIO ? 'Audio' : 'Video'}
                                                                </span>
                                                            &nbsp;call
                                                        </div>
                                                    </h5>

                                                    <div className="d-flex lh-100 justify-content-center mt-3">
                                                        <button className="btn btn-sm btn-circle btn-success" type="button" data-toggle="modal" data-target="#media"
                                                                onClick={
                                                                    () => mediaCall === AUDIO ? this.props.onHandleModal(AUDIO) : this.props.onHandleModal(VIDEO)
                                                                }>
                                                            {
                                                                mediaCall === AUDIO ?
                                                                    (
                                                                        <Icon
                                                                            name="phone-call"
                                                                            size="large"     // small, medium, large, xlarge
                                                                            animation={{
                                                                                type: "pulse",  // zoom, pulse, shake, flip
                                                                                hover: true,
                                                                                infinite: false
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Icon
                                                                            name="video"
                                                                            size="large"     // small, medium, large, xlarge
                                                                            animation={{
                                                                                type: "pulse",  // zoom, pulse, shake, flip
                                                                                hover: true,
                                                                                infinite: false
                                                                            }}
                                                                        />
                                                                    )
                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );

                            else {

                                const lastDotIdx = file.lastIndexOf('.');
                                const extension = file.substr(lastDotIdx+1);

                                if(USER_ID === userId)
                                    return (
                                        <div key={idx} id={idx} className="d-flex align-items-end justify-content-end mb-5 mt-5">
                                            <span className="avatar avatar-sm order-2 ml-3 rounded-circle" data-toggle="tooltip" data-placement="bottom" title={users[userId].fullName} style={{background: getPreferredColor(users[userId])}}>
                                                {getInitials(users[userId].fullName)}
                                            </span>
                                            <div className="order-1">
                                                <div className="card mb-3">
                                                    <span className="icon rounded-circle mr-3" style={{fill: 'white', background: getPreferredColor(users[userId])}}>
                                                      <Icon
                                                          name={getIcon(extension)}
                                                          size="medium"
                                                          fill="white"
                                                      />
                                                    </span>
                                                    <div>
                                                        <h6 className="mb-2 lh-100">
                                                            <a href={`${API_URL}/api/file/${file}`}>{file}</a>
                                                        </h6>
                                                        <p className="lh-100">{getFileSize(fileSize[0])}</p>
                                                    </div>
                                                </div>
                                                <p className="lh-100 text-right">{relativeTimeAgo(createdAt)}</p>
                                            </div>
                                        </div>
                                    );
                                else
                                    return (
                                        <div key={idx} id={idx} className="d-flex align-items-end mb-5 mt-5">
                                            <span className="avatar avatar-sm mr-3 rounded-circle" data-toggle="tooltip" data-placement="bottom" title={users[userId].fullName} style={{background: getPreferredColor(users[userId])}}>
                                                {getInitials(users[userId].fullName)}
                                            </span>
                                            <div className="order-1">
                                                <div className="card mb-3">
                                                    <span className="icon rounded-circle mr-3" style={{color: 'white', background: getPreferredColor(users[userId])}}>
                                                      <Icon
                                                          name={getIcon(extension)}
                                                          size="medium"
                                                      />
                                                    </span>
                                                    <div>
                                                        <h6 className="mb-2 lh-100">
                                                            <a href={`${API_URL}/api/file/${file}`}>{file}</a>
                                                        </h6>
                                                        <p className="lh-100">{getFileSize(fileSize[0])}</p>
                                                    </div>
                                                </div>
                                                <p className="lh-100 text-left">{relativeTimeAgo(createdAt)}</p>
                                            </div>
                                        </div>
                                    );
                            }
                        })}
                        <Typing typing={this.state.typing}/>
                        <div id='scrollToBottom'>&nbsp;</div>
                    </ScrollableFeed>
                </div>
            );
        }

    }
}

const Typing = ({typing}) => {

    if(typing > 0) {

        return (
            <div className="d-flex align-items-end mb-5">
                <span className="avatar avatar-sm mr-3 rounded-circle" style={{visibility: 'hidden'}}>JD</span>
                <div>
                    <div className="card mb-3">
                        <StageSpinner size={15} />
                    </div>
                </div>
            </div>
        );
    } else {
        return (null);
    }
};

Body.propTypes = {
    active: PropTypes.object
};

const mapStateToProps = (state) => ({
    error: getUsersError(state),
    users: getUsers(state),
    pending: getUsersPending(state),
    onlineUsers: getOnlineUsers(state),
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers: fetchUsersAction,
    getOnlineUsers: dispatchOnlineUsers,
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Body);
