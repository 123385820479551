import { activeChatroom } from "./activeChatroom";
import { usersReducer } from "./users";
import { onlineUsersReducer } from "./onlineUsers";

import { combineReducers } from "redux";


const rootReducer = combineReducers({
    activeChatroom: activeChatroom,
    users: usersReducer,
    onlineUsers: onlineUsersReducer
});

export default rootReducer;