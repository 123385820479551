import React, { Component } from 'react';
import PropTypes from 'prop-types';

import store from "../../store";
import socket, { userId } from "../../socket";
import {API_URL} from "../../config";
import {bindActionCreators} from "redux";
import {getActiveChatroom} from "../../reducers/activeChatroom";
import setActiveChatroom from "../../helpers/GetActiveChatroom";
import {connect} from "react-redux";

class Footer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            messageValue: ''
        };

        this.handleClick = this.handleClick.bind(this);

        this.socket = socket;
        this.emailInput = React.createRef();
    }

    handleChange(e) {

        e.preventDefault();

        const message = e.target.value;

        this.setState({ messageValue: message });
    }

    handleClick(e) {

        e.preventDefault();

        const message = this.state.messageValue;

        if (message !== '') {
            this.sendMessageApi(message);
            this.socket.emit('sendChat', {message: message}, userId);
            this.socket.emit('seenMessage', userId, store.getState().activeChatroom._id);
            this.setState({ messageValue: '' });
            this.emailInput.current.blur();
        }
    }

    sendMessageApi(message) {

        const data = {
            senderId: userId,
            messageBody: message
        };

        const { _id } = this.props.activeChatroom.activeChatroom;

        fetch(`${API_URL}/api/messages/room/${_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)
        })
            .then(res => res.json())
            .then(res => this.setState({ messages: res }))
            .catch(error => console.error(`Error fetching messages: ${error}`));
    }

    render() {
        return (
            <div className="chat-footer">
                <form>
                    <div className="input-group">
                        <input
                            className="form-control form-control-lg"
                            placeholder="Type message..."
                            value={this.state.messageValue}
                            onFocus={(e) => this.socket.emit('typing')}
                            onBlur={(e) => this.socket.emit('stopTyping')}
                            onChange={(e) => this.handleChange(e)}
                            ref={this.emailInput}
                            onKeyPress={
                                (e) => {
                                    if(e.key === 'Enter')
                                        this.handleClick(e);
                                }
                            }
                        />
                        <div className="input-group-append">
                            <button
                                className="btn btn-circle btn-neutral btn-sm mr-1"
                                onClick={(e) => this.handleClick(e)}
                            >
                                <i className="eva-hover">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24"
                                         className="eva eva-paper-plane eva-animation eva-icon-hover-pulse">
                                        <g data-name="Layer 2">
                                            <g data-name="paper-plane">
                                                <rect width={24} height={24} opacity={0}/>
                                                <path
                                                    d="M21 4a1.31 1.31 0 0 0-.06-.27v-.09a1 1 0 0 0-.2-.3 1 1 0 0 0-.29-.19h-.09a.86.86 0 0 0-.31-.15H20a1 1 0 0 0-.3 0l-18 6a1 1 0 0 0 0 1.9l8.53 2.84 2.84 8.53a1 1 0 0 0 1.9 0l6-18A1 1 0 0 0 21 4zm-4.7 2.29l-5.57 5.57L5.16 10zM14 18.84l-1.86-5.57 5.57-5.57z"/>
                                            </g>
                                        </g>
                                    </svg>
                                </i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>

        );
    }
}

Footer.propTypes = {
    active: PropTypes.object
};

const mapStateToProps = (state) => ({
    activeChatroom: getActiveChatroom(state)
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    activeChatroomData: setActiveChatroom
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);