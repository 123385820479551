import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListUsers from "../../ListUsers";
import socket, {userId} from "../../../socket";
import COLORS from "../../../constants/Colors";

class CreateChannel extends Component {

    constructor(props) {

        super(props);

        this.state = {
            channelName: '',
            firstMessage: '',
            members: undefined,
            preferredColor: undefined,
            filter: '',
            errors: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);

        this.socket = socket;
    }

    handleChange(e, field) {

        let value;

        if(field === 'preferredColor')
            value = e;
        else
            value = e.target.value;

        if(field in this.state)
            this.setState({ [field]: value});
    }

    handleCheckbox(members) {

        this.setState({members: members});
    }

    handleSubmit(e) {

        e.preventDefault();

        if(this.handleValidation(true)) {

            let {channelName, firstMessage, members, preferredColor} = this.state;

            if (members !== undefined) {
                members = Array.from(members);
            } else {
                members = [userId];
            }

            this.socket.emit('addRoom', channelName, members, firstMessage, preferredColor);

            this.setState({
                channelName: '',
                firstMessage: '',
                members: undefined,
                filter: '',
                errors: {},
                preferredColor: COLORS[0]
            });
        }
    }

    handleValidation(changeState = false) {

        const { channelName, firstMessage } = this.state;
        let errors = {};
        let formIsValid = true;

        if(channelName === '' || !channelName || typeof channelName === "undefined") {
            formIsValid = false;
            errors.channelName = 'Channel name cannot be empty!';
        }

        if(firstMessage === '' || !firstMessage || typeof firstMessage === "undefined") {
            formIsValid = false;
            errors.firstMessage = 'First message cannot be empty!';
        }

        if(changeState)
            this.setState({errors});

        return formIsValid;
    }

    render() {

        return (
            <div className={`modal fade ${this.props.showModal ? 'show' : ''}`} id="compose" tabIndex={-1} role="dialog" aria-labelledby="composeLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 id="composeLabel">Create a new channel</h4>
                            <button className="btn btn-sm btn-circle btn-neutral align-self-start" data-dismiss="modal" type="button" aria-label="Close" onClick={this.props.handleModal}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-close" aria-hidden="true"><g data-name="Layer 2"><g data-name="close"><rect width={24} height={24} transform="rotate(180 12 12)" opacity={0} /><path d="M13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z" /></g></g></svg>
                            </button>
                        </div>
                        <div className="modal-body p-0">
                            <ul className="nav nav-tabs nav-justified p-3 px-sm-5 rounded-0" role="tablist" aria-orientation="horizontal">
                                <li className="nav-item">
                                    <a className="nav-link active text-center" data-toggle="tab" href="#details" role="tab" aria-controls="details" aria-selected="true">Details</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-center" data-toggle="tab" href="#members" role="tab" aria-controls="members" aria-selected="false">Members</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-center" data-toggle="tab" href="#color" role="tab" aria-controls="color" aria-selected="false">Colors</a>
                                </li>
                            </ul>
                            <div className="py-5 px-3 px-sm-5">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="details" role="tabpanel">
                                        <form>
                                            <label htmlFor="subject">Channel name</label>
                                            <input className="form-control form-control-lg" id="subject" type="text" placeholder="What's the channel name?" value={this.state.channelName} onChange={(e) => this.handleChange(e, 'channelName')} required/>
                                            {this.state.errors.channelName ? (
                                                <small id="subjectHelpBlock" className="form-text text-danger">
                                                    {this.state.errors.channelName}
                                                </small>) : ''}
                                                <br />
                                            <label htmlFor="message">First message</label>
                                            <textarea className="form-control" id="message" rows={3} placeholder="Enter first message" value={this.state.firstMessage} onChange={(e) => this.handleChange(e, 'firstMessage')} required />
                                            {this.state.errors.firstMessage ? (
                                                <small id="messageHelpBlock" className="form-text text-danger">
                                                    {this.state.errors.firstMessage}
                                                </small>) : ''}
                                            <br />
                                        </form>
                                    </div>
                                    <div className="tab-pane fade" id="members" role="tabpanel">
                                        <form>
                                            <div className="input-group mb-5">
                                                <div className="input-group-prepend">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" className="eva eva-funnel-outline"><g data-name="Layer 2"><g data-name="funnel"><rect width={24} height={24} opacity={0} /><path d="M13.9 22a1 1 0 0 1-.6-.2l-4-3.05a1 1 0 0 1-.39-.8v-3.27l-4.8-9.22A1 1 0 0 1 5 4h14a1 1 0 0 1 .86.49 1 1 0 0 1 0 1l-5 9.21V21a1 1 0 0 1-.55.9 1 1 0 0 1-.41.1zm-3-4.54l2 1.53v-4.55A1 1 0 0 1 13 14l4.3-8H6.64l4.13 8a1 1 0 0 1 .11.46z" /></g></g></svg>
                                                </div>
                                                <input className="form-control form-control-lg" type="search" placeholder="Search" aria-label="Search" value={this.state.filter} onChange={(e) => this.handleChange(e, 'filter')} />
                                            </div>
                                        </form>
                                        <hr className="mb-0" />
                                        <div className="list-users" style={{maxHeight: '250px'}}>
                                            <ListUsers handleCkeckbox={(e) => this.handleCheckbox(e)} checked={this.state.members} filter={this.state.filter} insertMe={true} />
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="color" role="tabpanel">
                                        {
                                            COLORS.map((color, idx) => (
                                                <button className='btn btn-sm btn-circle m-2' key={color} style={{background: color, border: this.state.preferredColor === color ? '5px solid #000' : 'none'}} onClick={() => this.handleChange(color, 'preferredColor')}>
                                                    &nbsp;
                                                </button>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-block btn-lg btn-primary"
                                    data-dismiss={
                                        this.handleValidation() ? 'modal' : ''
                                    }
                                    type="button"
                                    aria-label={
                                        this.handleValidation() ? 'Close' : ''
                                    }
                                    onClick={this.handleSubmit}>
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}

CreateChannel.propTypes = {
    handleModal: PropTypes.func,
    showModal: PropTypes.bool
};

export default CreateChannel;
