import { ACTIVE_CHATROOM } from '../constants/ActionTypes';

export function activeChatroom(state = null, action) {
    switch(action.type) {
        case ACTIVE_CHATROOM:

            return {
                ...state,
                activeChatroom: action.activeChatroom
            };
        default:
            return state;
    }
};


export const getActiveChatroom = state => state.activeChatroom;